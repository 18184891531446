<template>
<div>
  <b-alert
    variant="warning"
    :show="betaWarning"
  >
  This feature is supported in Beta on this device ({{device}}). 
  If you experience disruption, please try from a computer with the Google Chrome browser.
  </b-alert>
  <b-alert
    variant="danger"
    :show="unsupported"
  >
  This feature is not supported on this device ({{device}}).
  Please try from a computer with the Google Chrome browser.
  </b-alert>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("device-support");
import { getBrowser } from "@/services/utils";

export default {
  props: {
    location: String,
  },
  data() {
    return {
      device:null,
      isSupportedDevice: false,

      betaWarning: false,
      unsupported: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.device = getBrowser();
      log.log(`detected device ${this.device}`);
      let complist = ["chrome", "live-wrapper", "mobilechrome"];
      this.isSupportedDevice = complist.includes(this.device);

      if (this.location == "guest" && !this.isSupportedDevice && this.$app.showBetaWarning) {
        this.betaWarning = true;
      } else if (this.location == "eventsCreate" && !this.isSupportedDevice) {
        this.unsupported = true;
      }
      this.$emit("set-unsupported", this.unsupported);
    },
  }
}

</script>

<style>

</style>