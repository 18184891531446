<template>
<div v-if="room && user">
  <!-- Debug -->
  <div v-if="$debug.isOn" class="mb-1 alert-info">
    <debug-obj label="room" :objData="room" folded/>
    <debug-obj label="roomUsers" :objData="roomUsers || room.users" folded/>
    <input type="checkbox" v-model="roomEditor"/> Room Editor
  </div>
  <!-- WebRTC only errors -->
  <div v-if="!debugNoWebRTC">
    <component
      :is="$app.useRtcComponent"
      ref="webrtc"
      :room="room"
      :roomUsers="roomUsers || room.users"
      :user="user"
      :userConfig="{'startMuted':startMuted,'settings':deviceSettings}"
      @userJoined="userJoinedRoom"
      @usersChange="webRTCUsersChanged"
      @userNotInRoom="userNotInRoom"
    />
  </div>
  <!-- room space -->
  <div class="d-flex flex-column align-content-between justify-content-center">
    <!-- Content -->
    <div v-if="room.content" class="w-100 text-center">
      <div style="white-space:pre-line;" v-if="typeof room.content == 'string'">
        {{ room.content }}
      </div>
      <div class="text-center mt-4 mb-4" v-else-if="room.content.type == 'questions'">
        <div class="mb-2" v-for="(q, index) in displayQuestions" v-bind:key="'q'+index">
          <b>{{ q.author }}'s question:</b><br/>
          {{ q.question }}
        </div>
        <div>
          <button class="btn btn-primary" @click="rotateQuestion">Next Question</button>
        </div>
      </div>
      <div class="text-center mt-4 mb-4" v-else-if="room.content.type == 'agenda'">
        <b>Agenda</b><br/>
        {{ room.content.agenda }}
      </div>
    </div>
    <!-- Video Layout -->
    <div v-if="users">
      <video-layout v-if="room.video" :layout="room.layout" :config="{volumeMuted, canExpand:true}"  :users="users"/>
      <audio-out v-else :volumeMuted="volumeMuted" :users="users"/>
    </div>
  </div>
  <!-- Connection -->
  <div class="container m-2">
    <!-- User List -->
    <div class="row pb-2 border-bottom" v-if="displayUsersList">
      <userlist ref="userlist"
        :users="users"
        :ui-config="{'showInvisible':userOptions.isModerator}"
      />
    </div>
    <!-- Settings -->
    <div class="row pb-2 border-bottom" v-if="displayDeviceSettings">
      <webrtc-settings
        ref="deviceSettings"
        :current="deviceSettings"
        @selection-saved="saveDeviceSettings"
        @close="displayDeviceSettings = false"/>      
    </div>
    <!-- Queue Controls -->
    <div class="row pb-2 border-bottom" v-if="queueEditor">
      <queue-editor :event="userOptions.canEditQueue" />
    </div>
    <!-- Room Controls -->
    <div class="row pb-2 border-bottom" v-if="roomEditor">
      <h3>Moderator</h3>
      <p>Time left: {{ moderatorTimer }}</p>
      <roomeditor ref="roomeditor" v-bind:room="room" />
      <div class="d-flex flex-row">
        <div class="mb-2">
          <button class="btn btn-danger mr-3" @click="closeRoom">Close&nbsp;Live&nbsp;Room</button>
        </div>
        <div>Go to like page, unless "Skip Like" is checked and applied.</div>
      </div>
    </div>
    <!-- Timer -->
    <div class="row pb-2 border-bottom" v-if="showTimer">
      <p class="font-weight-bold">Call ends in: {{ displayTimer }}</p>
    </div>
    <!-- Controls -->
    <div class="row pb-2 form-inline sub-mt-2 sub-mr-2">
      <!-- Userlist -->
      <button class="btn btn-primary" @click="displayUsersList = !displayUsersList">Users List</button>
      <!-- Settings -->
      <button class="btn btn-primary" @click="displayDeviceSettings = !displayDeviceSettings">Settings</button>
      <!-- Share Screen -->
      <div v-if="room.canShareScreen">
        <button class="btn btn-primary" @click="shareScreen()">Share Screen</button>
      </div>
      <!-- Video -->
      <div v-if="room.canTurnVideoOff">
        <button class="btn btn-primary" @click="setVideo(false)" v-if="!videoOff">Camera Off</button>
        <button class="btn btn-primary" @click="setVideo(true)" v-else>Camera On</button>
      </div>
      <!-- Chat -->
      <div v-if="room.canChat">
        <button class="btn btn-primary" @click="displayChat = !displayChat">Chat</button>
      </div>
      <!-- Mute -->
      <div v-if="$refs.webrtc">
        <button class="btn btn-primary" @click="$refs.webrtc.setMuted(false)" :disabled="muteButtonDisabled" v-if="$refs.webrtc.muted">Unmute</button>
        <button class="btn btn-primary" @click="$refs.webrtc.setMuted(true)" :disabled="muteButtonDisabled" v-else>Mute</button>
      </div>
      <!-- Volume Mute -->
      <div>
        <button class="btn btn-primary" @click="volumeMuted = false" :disabled="volumeMuteButtonDisabled" v-if="volumeMuted">Volume Restore</button>
        <button class="btn btn-primary" @click="volumeMuted = true" :disabled="volumeMuteButtonDisabled" v-else>Volume Mute</button>
      </div>
      <!-- Leave Call -->
      <div>
        <button class="btn btn-outline-danger" @click="confirmLeaveRoom()">Leave</button>
      </div>
    </div>
    <!-- User Specific Controls -->
    <div v-if="userOptions.canEditRoom || userOptions.canEditQueue" class="row border-top pt-2">
      <div class="d-flex flex-wrap">
        <!-- Room Editor -->
        <div v-if="userOptions.canEditRoom">
          <button class="btn btn-primary" @click="roomEditor = !roomEditor">Edit Room</button>
        </div>
        <!-- Queue Editor -->
        <div v-if="userOptions.canEditQueue">
          <button class="btn btn-primary" @click="queueEditor = !queueEditor">Edit Queue</button>
        </div>
      </div>
    </div>
    <!-- Awake Video -->
    <video class="render-invisible" style="height:4px;width:4px;" loop>
      <source type="video/webm" src="data:video/webm;base64,GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA=" />
      <source type="video/mp4" src="data:video/mp4;base64,AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw==" />
    </video>
  </div>
  <!-- Sidebar: Chat -->
  <b-sidebar id="sidebar-room-chat" right sidebar-class="border-left border-warning" shadow 
    :visible="displayChat" @change="chatSidebarChange">
    <div class="px-3 py-2">
      <b-alert variant="info" show>
        This chat is shared between <b>the users in this room</b>.
      </b-alert>
      <chat
        :channel="room.id"
        :author="user.name"
        :config="{autoScroll:true, limitOverflow:true}"
        />
    </div>
  </b-sidebar> 
</div>
</template>

<script>
import { getLog, setDbLog } from "@/services/log";
let log = getLog("room");
import { db } from "@/services/db";
import { formatTimer, formatTime } from "@/services/utils";
import { serverEpoch } from "@/services/server-time";
import WebRTC2 from "@/components/webrtc2";
import roomRTC from "@/components/roomRTC";
import roomeditor from "@/components/roomeditor";
import DebugObj from '@/components/debugObj';
import Userlist from '@/components/userlist';
import WebrtcSettings from '@/components/webrtcSettings';
import queueEditor from '@/components/queueEditor';
import VideoLayout from '@/components/videoLayout.vue';
import AudioOut from '@/components/audioOut.vue';
import { getSavedDeviceSettings, saveDeviceSettings } from "@/services/mediautils";
import Chat from '@/components/chat.vue';

export default {
  components: {
    roomRTC,
    peerRTC: WebRTC2,
    roomeditor,
    DebugObj,
    Userlist,
    WebrtcSettings,
    queueEditor,
    VideoLayout,
    AudioOut,
    Chat,
  },
  props: {
    roomId: String,
    user: Object,
    roomUsers: Array,
    userOptions: Object,
    config: {
      type: Object,
      default: () => { return {}; },
    }    
  },
  data() {
    return {
      // Room
      room: null,
      users: [],
      localUserIndex: 0,

      // Timer
      showTimer: false,
      displayTimer: "",
      pastWarning: true,
      // Moderator
      moderatorTimer: "",

      // bot Audio
      bot: null,
      playWarningVoice: false,

      // Room controls
      displayUsersList: false,
      displayDeviceSettings: false,
      displayChat: false,
      deviceSettings: null,
      videoOff: false,
      sharingScreen: false,
      volumeMuted: false,
      muteButtonDisabled: false,
      volumeMuteButtonDisabled: false,

      // Room content
      displayQuestions: [],
      warningSound: null,
      joinSound: null,

      // Monitoring
      beforeUnload: null,

      // Debug
      debugNoWebRTC: false,
      queueEditor: false,
      roomEditor: false,
    }
  },
  watch: {
    "room.contentIndex": {
      handler(value) {
        log.log("room.contentIndex updated", value);
        this.updateQuestions();
      }
    }
  },
  computed: {
    startMuted() {
      return this.userOptions.debugMuted||this.$debug.isOn;
    }
  },
  async mounted() {
    this.$app.router.disableContainer = true;

    this.volumeMuted = this.$debug.isOn;
    this.deviceSettings = getSavedDeviceSettings();
    this.joinRoom();
    this.beforeUnload = this.leaveRoom.bind(this, "window closed");
    window.addEventListener('beforeunload', this.beforeUnload);
    this.joinSound = await require("@/assets/ding.mp3");
    this.warningSound = await require("@/assets/end_of_call.mp3");
  },
  beforeDestroy() {
    log.log("beforeDestroy");
    this.$app.router.disableContainer = false;
    clearInterval(this.updateTimerHandler);
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  methods: {
    // Live Room
    async joinRoom() {
      setDbLog(log, this.roomId, `${this.user.id}_r`);
      log.log('loading room', this.roomId, this.userOptions);
      let room = await this.$bind("room", db.collection(this.config.roomCollection || "LiveRooms").doc(this.roomId));
      log.log('loaded room', room);
      this.displayUsersList = !this.room.video;
      this.$emit('roomJoined');
      if (this.updateTimer()) {
        this.$emit("roomLeft", {"reason":"timerexpiredonjoin"});
        return;
      }
      // Content display
      if (room.content && room.content.type == "questions")
        this.updateQuestions();
      this.playSound(this.joinSound);
      // Find bot
      if (room.bot)
        this.loadBot(room.bot);
      this.showTimer = false;
      this.pastWarning = false;
      this.pastBotEndOfCall = false;
      // Timer Update
      this.updateTimerHandler = setInterval(() => {
        this.updateTimer();
      }, 1000);
    },
    // Connection
    async loadBot(botId) {
      let bot = this.$bind("bot", db.collection("LiveVoiceBots").doc(botId));
      log.log(`Bot will play end of call at ${formatTime(bot.endVoiceMessageDuration)}`);
      this.botIntroPlayed = false;
    },
    userJoinedRoom(user) {
      log.log("Other User Joined Room", user);
      if (!this.botIntroPlayed && this.bot) {
        let vmkey = this.$parent.eventUser.conversationCount == 1 ? "firstStartVoiceMessage" : "startVoiceMessage";
        this.playVoice(vmkey, true);
        this.botIntroPlayed = true;
      }
      this.playSound(this.joinSound);
    },
    userNotInRoom() {
      log.log("userNotInRoom");
      this.$emit("roomLeft", {"reason":"user not in room"});
    },
    otherUser() {
      let roomUsers = this.roomUsers || this.room.users || [];
      return (roomUsers[0] == this.user.id) ? roomUsers[1] : roomUsers[0];
    },
    webRTCUsersChanged(users) {
      log.log("webRTCUsersChanged", users);
      this.users = users;
    },
    saveDeviceSettings(s) {
      log.log("saveDeviceSettings", s);
      this.deviceSettings = s;
      saveDeviceSettings(s);
      this.$refs.webrtc.createUserStream(s);
      this.displayDeviceSettings = false;
    },
    shareScreen() {
      log.log("shareScreen");
      this.$refs.webrtc.shareScreen();
    },
    setVideo(on) {
      log.log("setVideo", on);
      this.videoOff = !on;
      let s = Object.assign({}, this.deviceSettings);
      if (!on)
        s.video = on;   
      this.$refs.webrtc.createUserStream(s);
    },
    updateTimer() {
      if (this.room && this.room.timer) {
        // Moderator Timer (Real)
        let epoch = serverEpoch();
        //log.log(`epoch : ${epoch}s`);
        this.moderatorTimer = formatTimer(this.room.startTime.seconds, this.room.timer, epoch);

        // Guest
        let botEndOfCallVMDuration = this.bot ? this.bot.endVoiceMessageDuration : 0;
        let timeleft = this.room.startTime.seconds + (this.room.timer * 60) - epoch;
        log.log(`timeleft : ${timeleft}s`);
        // Close room
        if (timeleft < 0) {
          log.log("Room timer is over");
          this.pastWarning = true;
          this.leaveRoom("timerexpired");
          return true;
        }
        this.displayTimer = formatTimer(this.room.startTime.seconds, this.room.timer - botEndOfCallVMDuration / 60, epoch);
        // Warning sound
        let warningTriggerTimer = 25 + botEndOfCallVMDuration;
        if (!this.pastWarning && timeleft < warningTriggerTimer) {
          this.playSound(this.warningSound);
          if (this.playWarningVoice)
            this.playVoice("warningVoiceMessage", false);
          this.showTimer = true;
          this.pastWarning = true;
        }
        // End of call bot
        if (this.bot && !this.pastBotEndOfCall && timeleft < botEndOfCallVMDuration) {
          this.playVoice("endVoiceMessage", true);
          this.showTimer = false;
          this.pastBotEndOfCall = true;
        }
      }
    },
    async playSound(sound) {
      if (this.userOptions.debugMuted) {
        log.log("Warning sound muted");
        return;
      }
      this.audio = new Audio(sound);
      this.audio.type = "audio/mp3";
      try { 
        await this.audio.play();
        log.log("playSound worked");
      } catch (error) {
        log.log("Playing failed...", error);
      }
    },
    // Bot
    muteForDuration(duration) {
      log.log(`Muting for ${duration} seconds`);
      if (!this.$refs.webrtc.muted)
        this.$refs.webrtc.setMuted(true);
      setTimeout(() => {
        if (this.$refs.webrtc.muted)
          this.$refs.webrtc.setMuted(false);
      }, duration * 1000);
    },    
    playVoice(key, mute) {
      let vm = this.bot && this.bot[key];
      if (!vm)
        return;
      let vmduration = this.bot[key + "Duration"];
      let fun = () => {
        this.muteForDuration(vmduration);
        this.$refs.userlist.addBotForDuration(this.bot.name, vmduration, mute);
        this.muteButtonDisabled = true;
        setTimeout(() => { this.muteButtonDisabled = false }, 1000 * vmduration);
      }
      log.log(`playVoice ${key} ${vm} mute:${mute}`);
      if (this.userOptions.debugMuted) {
        log.log("End of call voice muted");
        fun();
        return;
      }
      this.audio = new Audio(vm);
      this.audio.type = "audio/mp3";
      this.audio
        .play()
        .then(() => {
          log.log("Playing worked");
          fun();
        })
        .catch(error => {
          log.log("Playing failed...", error);
        });
    },
    cleanAudio() {
      if (this.audio) this.audio.pause();
    },
    // leave Room
    async confirmLeaveRoom() {
      let leaveMessage = this.$parent.event?.leavingCallMessage || "Are you sure you want to leave the call?";
      let confirm = await this.$bvModal.msgBoxConfirm(leaveMessage, {
        title: "Please confirm",
        okTitle: 'Confirm',
        cancelTitle: 'Cancel',
        centered: true
      });
      if (confirm)
        this.leaveRoom();
    },
    leaveRoom(reason) {
      this.$refs.webrtc.leaveRoom();
      // Stop timer
      clearInterval(this.updateTimerHandler);
      // Clean audio if was playing when leaving
      this.cleanAudio();
      // Alert parent
      let params = {
        reason: reason || "manual confirmation",
        pastWarning: this.pastWarning,
        other: this.otherUser()
      };
      // Clear room
      setDbLog(log, false);
      this.$emit("roomLeft", params);
    },
    closeRoom() {
      this.$refs.roomeditor.roomConfigUpdate();
      db.collection("LiveRooms")
        .doc(this.roomId)
        .update({ timer: 0 });
    },

    // room content questions
    updateQuestions() {
      let contentIndex = this.room.contentIndex || 0;
      let question = this.room.content.questions[contentIndex];
      this.displayQuestions = question ? [question] : [];
    },
    rotateQuestion() {
      let contentIndex = this.room.contentIndex || 0;
      contentIndex = (contentIndex + 1) % this.room.content.questions.length;
      db.collection(`LiveRooms`).doc(this.room.id).update({contentIndex});
    },
    chatSidebarChange(visible) {
      log.log("chatSidebarChange", visible);
      this.$app.router.showRightSideBar = visible || this.showChat;
      this.displayChat = visible;
    },
  }
}
</script>

<style scoped>

.flex-wrap > div {
  display: inline !important;
  margin: 0 0.5rem 0.5rem 0 !important;
}

</style>