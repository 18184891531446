<template>
<div class="videoBg">
  <!-- full -->
  <div v-if="fullScreen">
    <video-screen :class="{'videoFull':true}"
      :u="fullScreen"
      @reset="fullScreen = null; vignetteUser = null;"
      :config="{canReset:true, showNote:config.showNote}"
      v-if="fullScreen.screenStream"/>
    <video-user :class="{'videoVignette':true}"
      v-if="vignetteUser"
      :u="vignetteUser"
      @close="vignetteUser = null"
      :config="{volumeMuted, showNote:config.showNote, canClose:true}"/>
  </div>
  <div v-else-if="fullUser">
    <video-user :class="{'videoFull':true}"
      :u="fullUser"
      @reset="fullUser = null"
      :config="{volumeMuted, showNote:config.showNote, canReset:true}"/>
  </div>
  <!-- vignette -->
  <div class="vignetteContainer" v-if="layout == 'vignette'">
    <template v-for="(u, i) in users">
    <!-- camera -->
    <video-user :class="[!u.local ? 'videoFull' : 'videoVignette']"
      :u="u"
      :config="{volumeMuted, showNote:config.showNote}"
      v-bind:key="'vid_' + i" 
      v-if="!u.invisible"/>
    <!-- screen -->
    <video-screen :class="{'videoFull':true}"
      :u="u"
      v-bind:key="'vid_ss_' + i" 
      v-if="!u.invisible && u.screenStream"/>
    </template>
  </div>
  <!-- Side by side -->
  <div class="vignetteContainer" v-else-if="layout == 'side-by-side'">
    <template v-for="(u, i) in users">
    <!-- camera -->
    <video-user
      class="align-middle"
      :class="{'videoSplit':numColumns == 2, 'videoSplit4':numColumns == 4, 'videoSplit6':numColumns == 6, 'embed-responsive':displaySquare, 'embed-responsive-1by1':displaySquare}"
      :u="u"
      :config="{volumeMuted, displaySquare, showNote:config.showNote, canExpand:config.canExpand, canVignette:(fullScreen == u)}"
      @expand="fullUser = u; fullScreen = null;"
      @vignette="vignetteUser = u;"
      v-bind:key="'vid_' + i" 
      v-if="!u.invisible && (fullUser != u && vignetteUser != u)"/>
    <!-- screen -->
    <video-screen
      class="align-middle"
      :class="{'videoSplit':numColumns == 2, 'videoSplit4':numColumns == 4, 'videoSplit6':numColumns == 6}"
      :u="u"
      :config="{canExpand:config.canExpand, showNote:config.showNote}"
      @expand="fullScreen = u; vignetteUser = u; fullUser = null;"
      v-bind:key="'vid_ss_' + i" 
      v-if="!u.invisible && u.screenStream && fullScreen != u"/>
    </template>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('video-layout', false);
import videoUser from "./videoUser.vue";
import videoScreen from "./videoScreen.vue";
import { getStreamDimensions } from "@/services/mediautils";

export default {
  components: {
    videoUser,
    videoScreen,
  },
  props: {
    layout: {
      type: String,
      default: 'side-by-side',
    },
    users: Array,
    config: {
      type: Object,
      default: () => { return {}; },
    },
  },
  data() {
    return {
      fullUser: null,
      fullScreen: null,
      vignetteUser: null,
      displaySquare : false,
    }
  },
  computed: {
    volumeMuted() { return this.config.volumeMuted; },
    numColumns() { return this.config.numColumns || 2; }
  },
  watch: {
    users() {
      this.updateDisplaySquare();
      this.updateFullScreen();
    }
  },
  methods: {
    isValidDimension(dim) {
      return dim.height !== undefined && dim.height !== undefined;
    },
    updateFullScreen() {
      log.log("updateFullScreen", this.fullUser, this.fullScreen, this.vignetteUser);
      if (this.fullUser)
        this.fullUser = this.users.find((u) => u.id == this.fullUser.id);
      if (this.fullScreen)
        this.fullScreen = this.users.find((u) => u.id == this.fullScreen.id);
      if (this.vignetteUser)
        this.vignetteUser = this.users.find((u) => u.id == this.vignetteUser.id);
    },
    async updateDisplaySquare() {
      if (!this.users || this.users.length < 2 || !this.users[0].stream || !this.users[1].stream) {
        this.displaySquare = false;
        log.log(`displaySquare = false`);
        return;
      }
      let si0 = await getStreamDimensions(this.users[0].stream);
      let si1 = await getStreamDimensions(this.users[1].stream);
      log.log('si0', si0, 'si1', si1);
      let si0r = (si0.width / si0.height) > 1;
      log.log(`si0 = w:${si0.width} h:${si0.height}, si0r=${si0r}`);
      let si1r = (si1.width / si1.height) > 1;
      log.log(`si1 = w:${si1.width} h:${si1.height},  si1r=${si1r}`);
      this.displaySquare = (si0r != si1r) && this.isValidDimension(si0) && this.isValidDimension(si1);
      log.log(`displaySquare = ${this.displaySquare}, si0r=${si0r}, si1r=${si1r}`);
    }
  }
}
</script>

<style lang="scss" scoped>

@import "./video.scss";

.videoBg {
  width: 100%;
  background-image: linear-gradient(lightgrey, white);
}

.vignetteContainer {
  width: 100%;
  position: relative;
}

</style>