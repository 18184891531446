<template>
<div>
  <b-alert variant="danger" :show="errorMsg != null" @click="errorMsg = null" dismissible>
    Error: {{ errorMsg }}<br/>
    Please try reloading the page. Thanks!
  </b-alert>
  <div v-if="$debug.isOn" class="alert-info mb-2">
    AgoraRTC, channel: {{ channel }}, userId: {{ userId }}, joined: {{ joined }}
    <debug-obj label="stream" :objData="{stream}" /> 
    <debug-obj label="users" :objData="users" /> 
    <div class="form-inline sub-mt-2 sub-mr-2">
      <button class="btn btn-primary btn-sm" @click="leave()">Leave</button>
      <button class="btn btn-primary btn-sm" @click="join()">Join</button>
      <button class="btn btn-primary btn-sm" @click="volumeMuted = !volumeMuted">Toggle Users Mute</button>
    </div>
    Users (without self)
    <video-layout :users="users" :config="{volumeMuted}" />
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("agoraRTC")
import AgoraRTC from "agora-rtc-sdk-ng";
import { getStreamInfo } from "@/services/mediautils";
import videoLayout from './videoLayout.vue';
import { removeNullInArray, randomString } from '@/services/utils';
import { getChannelToken } from '@/services/functions';

let agoraAppID = "3929bc543a9e416eb75112cf8b1a8b22";
let testToken = null;

export default {
  components: { videoLayout },
  props: {
    stream: null,
    screenStream: null,
    userId: { 
      type: String,
      default: () => { return `anon${randomString(32)}`; }
    },
    channel: String,
    config: {
      type: Object,
      default: () => { return {}; }
    }
  },
  data() {
    return {
      client: null,
      screenClient: null,
      errorMsg: null,
      joined: false,
      users: [],
      volumeMuted: true,
      audioTracks: {},
      videoTracks: {},
    };
  },
  watch: {
    stream() {
      this.updateStream();
    },
    screenStream() {
      this.updateScreenStream();
    },
    users(value) {
      this.$emit("users-change", value);
    }
  },
  mounted() {
    if (this.config.debugVolumeMuted !== undefined)
      this.volumeMuted = this.config.debugVolumeMuted;
    this.init();
  },
  beforeDestroy() {
    this.leave();
  },
  methods: {
    log() {
      let args = [...arguments];
      let first = args.shift();
      log.log(`[${this.channel}] ${first}`, ...args);
    },
    init() {
      this.log("init");

      //AgoraRTC.setLogLevel(3);
      let client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      this.client = client;

      client.on("token-privilege-will-expire", async () => {
        log.log("token-privilege-will-expire");
        client.renewToken(await this.getToken(this.userId));
      });

      client.on("token-privilege-did-expire", async () => {
        this.errorMsg = "token-privilege-did-expire";
        log.error("token-privilege-did-expire");        
      });

      client.on("user-published", async (user, mediaType) => {
        this.log("user-published", user, mediaType);
        if (user.uid.startsWith(this.userId)) {
          log.log("ignoring stream coming from this user");
          return;
        }
        await client.subscribe(user, mediaType);
        if (mediaType == "video")
          this.videoTracks[user.uid] = user.videoTrack?.getMediaStreamTrack();
        else if (mediaType == "audio")
          this.audioTracks[user.uid] = user.audioTrack?.getMediaStreamTrack();
        let tracks = removeNullInArray([this.videoTracks[user.uid], this.audioTracks[user.uid]]);
        this.log("tracks", tracks);
        let stream = new MediaStream(tracks);
        this.processEvent('peer-did-enter', { id: user.uid, stream: stream });
      })

      client.on("user-unpublished", async (user) => {
        if (user.uid.startsWith(this.userId)) {
          log.log("ignoring stream coming from this user");
          return;
        }
        this.processEvent('peer-did-leave', { id: user.uid });
      })

      this.join();
    },
    async getToken(userId) {
      if (testToken) {
        this.log(`Using test token`);
        return testToken;
      }
      let response = await getChannelToken({channel:this.channel, userId});
      this.log(`getToken for ${userId}@${this.channel} response`, response);
      return response.data.token;
    },
    async join() {
      this.log(`join ${this.userId}@${this.channel}`, this.stream);
      if (this.joined) {
        log.error("already joined, check why join is called multiple times");
        await this.leave();
      }
      try {
        let token = await this.getToken(this.userId);
        await this.client.join(agoraAppID, this.channel, token, `${this.userId}`);
        this.joined = true;
        if (this.stream) {
          await this.updateStream();
        }
        if (this.screenStream) {
          await this.updateScreenStream();
        }
        this.$emit('connection-change', true);
      }
      catch (e) {
        this.errorMsg = "Unable to join";
        log.error("error", e);
        this.$emit('connection-change', false);
      }
    },
    async leave() {
      this.log("leave");
      if (!this.joined) {
        log.log("not joined, nothing to do.");
        return;
      }
      await this.client.leave();
      if (this.screenClient)
        await this.screenClient.leave();
      this.joined = false;
      this.users = [];
      this.$emit('connection-change', false);
      this.$emit('broadcasting-change', false);
    },
    // process Viejo events
    processEvent(eventId, params) {
      this.log("processEvent", eventId, params);
      // updates users
      if (eventId == "peer-did-leave")
        this.userRemove(params.id);
      else
        this.userSetMerge(params.id, {name: "N/A", stream:params.stream});
      // process single broadcast
      let stream = params.stream?.getVideoTracks()[0] ? params.stream : null;
      if (!this.broadcasterId && stream) {
        this.broadcasterId = params.id;
        this.emitStreamChange(stream);      
      } else if (this.broadcasterId == params.id && !stream) {
        this.broadcasterId = null;
        this.emitStreamChange(stream);
      }
    },
    emitStreamChange(stream) {
      this.log("emitStreamChange", stream, getStreamInfo(stream));
      this.$emit('stream-change', stream);
    },
    // Users
    userSetMerge(id, data) {
      // screen stream
      let parts = id.split("-");
      if (parts.length > 1) {
        this.log("adding screenStream", id);
        this.userSetMerge(parts[0], {screenStream:data.stream});
        return;
      }
      // full user
      let index = this.users.findIndex((u) => u.id == id);
      if (index < 0) {
        this.log("adding user", id);
        index = this.users.length;
        this.users.push({id});
      }
      this.$set(this.users, index, Object.assign(this.users[index], data));
    },
    userRemove(id) {
      // removing screen stream
      let parts = id.split("-");
      if (parts.length > 1) {
        this.log("removing screenStream", id);
        this.userSetMerge(parts[0], {screenStream:null});
        return;
      }
      // removing user
      this.log("removing user", id);
      let index = this.users.findIndex((u) => u.id == id);
      if (index >= 0)
        this.users.splice(index, 1);
      else
        this.log("user not found", id);
    },
    createCustomStream(stream, videoOnly) {
      let res = [];
      let videoMediaStreamTrack = stream.getVideoTracks()[0];
      if (videoMediaStreamTrack) {
        let localVideoTrack = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoMediaStreamTrack,
        });
        res.push(localVideoTrack);
      }
      let audioMediaStreamTrack = stream.getAudioTracks()[0];
      if (!videoOnly && audioMediaStreamTrack) {
        let localAudioTrack = AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioMediaStreamTrack,
        });
        res.push(localAudioTrack);
      }
      return res;
    },
    // Update stream
    async updateStream() {
      this.log("updateStream", this.stream?.id);
      if (!this.joined) {
        this.log("need to join before updating stream, ignoring");
        return;
      }
      if (this.stream) {
        await this.client.unpublish();
        let tracks = this.createCustomStream(this.stream);
        await this.client.publish(tracks);
        this.$emit('broadcasting-change', true);
      } else {
        this.client.unpublish();
        this.$emit('broadcasting-change', true);
      }
    },
    async updateScreenStream() {
      this.log("updateScreenStream", this.screenStream);
      if (!this.joined) {
        this.log("need to join before updating stream, ignoring");
        return;
      }
      if (!this.screenClient && this.screenStream) {
        this.screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        let userId = `${this.userId}-0`;
        let token = await this.getToken(userId);
        this.screenClient.on("token-privilege-will-expire", async () => {
          log.log("screenClient token-privilege-will-expire");
          this.screenClient.renewToken(await this.getToken(userId));
        });
        this.screenClient.on("token-privilege-did-expire", async () => {
          this.errorMsg = "screenClient token-privilege-did-expire";
          log.error("screenClient token-privilege-did-expire");        
        });
        await this.screenClient.join(agoraAppID, this.channel, token, userId);
      }
      if (this.screenStream) {
        await this.screenClient.unpublish();
        let tracks = this.createCustomStream(this.screenStream);
        await this.screenClient.publish(tracks);
      } else {
        await this.screenClient.unpublish();
      }
    }
  }
}
</script>

<style>

</style>