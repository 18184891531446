<template>
<div>
  <div class="text-center" v-if="other">
    <!-- dating mode -->
    <div v-if="mode == 'like'">
      <h2>{{ other.name }}, {{ other.age }}</h2>
      <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <img :src="other.picture0" class="m-2 imglike"/>
        <img :src="other.picture1" class="m-2 imglike"/>
      </div>
    </div>
    <!-- pro mode -->
    <div v-else>
      <h2>{{ other.name }}</h2>
      <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <img :src="other.picture0" class="m-2 imglike w-50"/>
      </div>
    </div>
    <div class="m-3">
      <h2 v-if="mode == 'like'">Do you like {{ other.name }}?</h2>
      <h2 v-else>Did you enjoy the conversation with {{ other.name }}?</h2>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-primary btn-large mr-5" @click="feedback(true)">Yes</button>&nbsp;
        <button type="button" class="btn btn-primary btn-large ml-5" @click="feedback(false)">No</button>
      </div>
    </div>
  </div>
  <div v-else>
    Loading user profile...
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("conv-feedback");
import { db } from '@/services/db';
import { chatSendStatus } from "@/components/chat";

export default {
  props: {
    event: Object,
    user: Object,
    params: Object,
  },
  data() {
    return {
      mode: null,
      other: null,
    }
  },
  async mounted() {
    log.log("mounted", this.params);
    this.mode = this.event.workflowOptions.askFeedback;
    await this.$bind("other", db.collection("LiveUsers").doc(this.params.otherId || "hIVovKjI3OOv3SM1bGGP"));
    if (this.$parent.debugAutoAccept) {
      setTimeout(() => {
        this.feedback((Math.random() * 3) > 1);
      }, 2000 * Math.random());
    }
  },
  methods: {
    async feedback(yes) {
      log.log("feedback", yes);
      if (!this.other)
        return;
      await db.collection(`LiveEvents/${this.event.id}/matches`)
        .doc(`${this.user.id}_${this.other.id}`)
        .set(yes ? { pick: true } : { discard: true }, { merge: true });
      chatSendStatus(
        this.user.id,
        `You ${yes ? "liked" : "did not like"} ${this.other.name}.`
      );
      this.$parent.eventUserBehavior.on("feedbackGiven");
    },
  }
}
</script>

<style scoped>

.imglike {
  width: 100%;
  overflow: hidden;
  border: solid 1px #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000;
  object-fit: contain;
}

</style>