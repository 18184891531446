import { getLog } from "@/services/log";
let log = getLog("notifs");
import { db } from "@/services/db";
import { appConfig } from "@/services/appconfig";

export async function sendEmailToHost(userName, event, params) {
  log.log("sendEmailToHost");
  let hostName = "Laurent";
  let hostEmail = "laurent.mascherpa@gmail.com";
  if(hostEmail) {
    await db.collection("ExtMail").add({
      to: hostEmail,
      from: appConfig.contactEmail,
      message: {
        subject: `${userName} is joining your ${appConfig.productName}`,
        html: `Hi ${hostName},<br/>
          <br/>
          ${userName} is requesting a meeting for ${event.nextDate.toDate()}<br/>
          <br/>
          Duration estimated: ${params.estimate}<br/>
          <br/>
          Agenda for the meeting:<br/>
          ${params.agenda}<br/>
        `,
      },
    });
  } else {
    log.log("Host has no email address");
  }
}