<template>
<div>
  <b-alert variant="warning m-2" :show="true">
    roomRTC in Beta.
  </b-alert>
  <user-media 
    ref="usermedia"
    :user="user"
    :config="Object.assign({video:room.video}, userConfig)"
    @user-update="updateLocalUser"
  />
  <agora-rtc
    ref="agora"
    :stream="localUser && localUser.stream"
    :screenStream="localUser && localUser.screenStream"
    :userId="user.id"
    :channel="room.id"
    @users-change="updateRtcUsers"
    />
  <div class="alert-info mb-2" v-if="$debug.isOn">
    RoomRTC
    <debug-obj label="users" :objData="users"/>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("roomRTC")
import AgoraRtc from "./agoraRTC.vue"
import { db } from '@/services/db';
import { leftJoin } from '@/services/utils';
import UserMedia from './userMedia.vue';
import { objectFilter, removeUndefined } from '../services/utils';

// Adapter to Agora
export default {
  components: {
    AgoraRtc,
    UserMedia,
  },
  props: {
    user: Object,
    room: Object,
    userConfig: {
      type: Object,
      default: () => { return {} },
    },
  },
  data() {
    return {
      stream: null,
      localUser: null,

      // contains [id, stream, screenStream]
      rtcUsers: [],
      // contains [name, muted, image, invisible]
      roomUsers: [],
      // merged result
      users: [],

      roomUserRef: null,
    };
  },
  computed: {
    muted() { return this.localUser?.muted; },
    screenStream() { return this.localUser?.screenStream; },
  },
  watch: {
    users(value) {
      this.$emit("usersChange", value);
    },
    roomUsers() {
      this.updateMergedUsers();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {  
    this.leaveRoom();
  },
  methods: {
    async init() {
      log.log("init", this.user);
      this.initRoomUsers();
      this.initRoomUser({});
    },
    updateLocalUser(localUser) {
      this.localUser = localUser;
      this.updateMergedUsers();
      this.updateRoomUser(removeUndefined(objectFilter(localUser, (val, key) => ["name", "id", "muted", "invisible", "image"].includes(key))));
    },
    updateRtcUsers(rtcUsers) {
      //log.log("updateRtcUsers", rtcUsers);
      this.rtcUsers = rtcUsers;
      this.updateMergedUsers();
    },
    updateMergedUsers() {
      //log.log("updateMergedUsers", this.rtcUsers, this.roomUsers);
      this.users = leftJoin([this.localUser || {}, ...this.rtcUsers], this.roomUsers, "id", "id");
      //log.log("updateMergedUsers res=", this.users);
    },
    async createUserStream(settings) {
      this.$refs.usermedia.createStream(settings);
    },
    shareScreen() {
      this.$refs.usermedia.shareScreen();
    },
    setMuted(muted) {
      this.$refs.usermedia.setMuted(muted);
    },
    async leaveRoom() {
      log.log("leaveRoom");
      this.$refs.usermedia?.destroyStream();
      this.$refs.usermedia?.stopScreenShare();
      this.$refs.agora.leave();
      await this.deleteRoomUser();
    },
    // Room Users
    async initRoomUsers() {
      this.$bind("roomUsers", db.collection(`LiveRooms/${this.room.id}/users`));
    },
    async initRoomUser(data) {
      this.roomUserRef = db.doc(`LiveRooms/${this.room.id}/users/${this.user.id}`);
      this.roomUserRef.set(data);
    },
    async updateRoomUser(data) {
      log.log("updateRoomUser", data);
      if (this.roomUserRef) {
        try {
          await this.roomUserRef.update(data);
        } catch (e) {
          log.log("update error", e);
        }
      }
    },
    async deleteRoomUser() {
      log.log("deleteRoomUser");
      if (this.roomUserRef) {
        try {
          await this.roomUserRef.delete();
        } catch (e) {
          log.log("item already deleted", e);
        }
        this.roomUserRef = null;
      }
    }
  }
}
</script>

<style>

</style>