<template>
<div class="container bg-light rounded p-3 mb-3">
  <div v-if="$debug.isOn" class="alert-info">
    layout:
    <b-form-select inline size="sm" v-model="layout" :options="layoutOptions"/>
    canShareScreen:
    <input type="checkbox" v-model="canShareScreen"/><br/>
    canTurnVideoOff:
    <input type="checkbox" v-model="canTurnVideoOff"/><br/>
    startMuted:
    <input type="checkbox" v-model="startMuted"/><br/>
    recording:
    <input type="checkbox" v-model="recording"/>
  </div>
  <div class="mb-2">Content:<br/> 
    <textarea rows="5" cols="40" v-model="content" placeholder="<Paste here the questions>"/>
  </div>
  <div class="mb-2">Video: <input type="checkbox" v-model="video"></div>
  <div class="mb-2">Timer: <input type="number" v-model="timer"/></div>
  <div class="mb-2"><button class="btn btn-primary" @click="roomConfigUpdate">Apply Changes to Room</button></div>
</div>
</template>

<script>
import { db } from '../services/db';
import { getLog } from "../services/log";
import { removeNull } from '../services/utils';
let log = getLog('roomeditor');

export default {
  props: {
    room: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      content: "",
      timer: 0,
      video: false,
      layout: null,
      canShareScreen: false,
      canTurnVideoOff: false,
      startMuted: false,
      recording: false,

      // Options
      layoutOptions: [ null, "side-by-side", "vignette"],
    }
  },
  mounted() {
    this.content = this.room.content;
    this.timer = this.room.timer;
    this.video = this.room.video;
    this.layout = this.room.layout || null;
    this.canShareScreen = this.room.canShareScreen;
    this.canTurnVideoOff = this.room.canTurnVideoOff;
    this.recording = this.room.recording;
    this.startMuted = this.room.startMuted
  },
  methods: {
    roomConfigUpdate() {
      let data = {
        content: this.content,
        timer: this.timer,
        video: this.video,
        layout: this.layout,
        canShareScreen: this.canShareScreen,
        canTurnVideoOff: this.canTurnVideoOff,
        recording: this.recording,
        startMuted: this.startMuted,
      };
      log.log("Updating Live room", data);
      removeNull(data);
      db.collection("LiveRooms").doc(this.room.id).update(data);
      this.$emit("done");
    }
  }
}
</script>
