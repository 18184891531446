<template>
<div>
  <user-media
    ref="userMedia"
    :config="{video: true, settings:deviceSettings}"
    @user-update="updateLocalUser"
    />
  <video class="mirror w-100" :srcObject.prop="localUser.stream" autoplay playsinline muted/>
  <div class="form-inline sub-mr-2 sub-mt-2 mb-2">
    <div>Microphone</div>
    <div class="inner-container">
      <audioviz :stream="localUser.stream"/>
      <div class="overlay">
        <font-awesome-icon icon="microphone" :style="{ color: 'white' }"/>
      </div>
    </div>
    <button class="btn btn-primary" @click="showSettings = !showSettings">Settings</button>
    <button class="btn btn-outline-danger" v-if="$debug.isOn" @click="clearDeviceSettings">Clear Settings (localStorage)</button>
  </div>
  <webrtc-settings
    class="border-top"
    v-if="showSettings"
    :current="deviceSettings"
    @selection-saved="setDeviceSettings"
    @close="showSettings = false;"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('preview-gum');
import { getSavedDeviceSettings, saveDeviceSettings } from "@/services/mediautils"
import audioviz from './audioviz.vue';
import WebrtcSettings from './webrtcSettings.vue';
import UserMedia from './userMedia.vue';

export default {
  components: { audioviz, WebrtcSettings, UserMedia },
  data() {
    return {
      showSettings: false,      
      deviceSettings: getSavedDeviceSettings(),
      localUser: {},
    }
  },
  mounted() {
    log.log("mounted");
  },
  watch: {
    "localUser.stream":  {
      handler(value) {
        this.$emit('on-stream', value);
      }
    }
  },
  methods: {
    updateLocalUser(localUser) {
      this.localUser = localUser;
    },
    setDeviceSettings(s) {
      log.log("setDeviceSettings", s);
      this.deviceSettings = s;
      saveDeviceSettings(s);
      this.$refs.userMedia.createStream(this.deviceSettings);
    },
    clearDeviceSettings() {
      log.log("clearDeviceSettings");
      localStorage.removeItem('device-settings');
      this.deviceSettings = {};
    }
  }
}
</script>

<style lang="scss" scoped>

@import "./video.scss";

.inner-container {
  position: relative;
}

.overlay {
  position: absolute;
  left: 18px;
  top: 11px;
  font-size: 20px;
  z-index: 2;
}

</style>