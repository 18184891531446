<template>
  <div ref="top">
    <div class="guest">
      <b-alert variant="warning" show v-if="errorMsg">
        Error: {{ errorMsg }}
      </b-alert>
      <div v-if="$debug.isOn || debugMode" class="mb-1">
        <debug-obj label="event" :objData="event" folded/>
        <debug-obj label="eventUser" :objData="eventUser" :folded="!debugMode"/>
      </div>
      <div v-if="page && ($debug.isOn || debugMode)" class="alert-info form-inline sub-mr-2 mb-1">
        <debug-obj label="page" :objData="page"/>
          <b-form-input type="text" small v-model="pageText" placeholder="pageid"/>
          <button class="btn btn-sm btn-primary" @click="setPage()">Set</button>
        <debug-obj label="params" :objData="pageParams"/>
      </div>
      <device-support location="guest"/>
      <b-alert variant="warning" :show="debugAutoAccept || debugMuted">
        Warning, this user is running in automatted test mode. Do not use with customers.
        </b-alert>
      <!-- No Profile -->
      <div v-if="!user">
        <div v-if="$app.limitedAvailability">
          <div class="d-flex flex-column text-center">
            <div class="m-4">
              <a :href="$app.productURL"><img :src="$app.productLogoSmall" width="180"/></a>
            </div>
            <p>{{ $app.tagLine }}</p>
            This service is in limited availability, and requires an invitation.
          </div>
        </div>
        <div v-else>
          <button class="btn btn-primary" @click="$router.push('/guest/signup')">Sign up</button>
        </div>
      </div>
      <!-- Live Room -->
      <div class="liveroom" v-else-if="roomId">
        <room
          ref="room"
          :roomId="roomId"
          :user="user"
          :roomUsers="eventUsersIds"
          :userOptions="{debugMuted, isModerator, canEditRoom:isModerator, canEditQueue:(eventUser && eventUser.role == 'host'? event: undefined)}"
          @roomJoined="roomJoined"
          @roomLeft="roomLeft"
        />
      </div>
      <!-- Terms of Use -->
      <div class="tou" v-else-if="!user.waiverSigned">
        <tou v-on:signToU="signToU" />
      </div>
      <!-- Waiting Room -->
      <div v-else-if="event && page">
        <div class="d-flex vh-main">
        <component
          :class="pageClasses[page] || 'col-12 my-auto'"
          :is="page"
          :params="pageParams"
          :user-id="user.id"
          :user="user"
          :event-user="eventUser"
          :event="event"
          :event-id="event.id"
          @join-room="joinRoom"
        />
        </div>
      </div>
      <!-- No state -->
      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("guest");
import { db } from "@/services/db";
import { initServerTime } from "@/services/server-time";
import { registerUserToEvent } from "@/services/user";
import { getEventUserBehavior, eventTypeNames } from "@/services/event-manager";
import { chatSendStatus } from "@/services/chat";
import room from "@/components/room";

// Pages
import profile from "@/pages/profileForm";
import tou from "@/pages/tou";
import convFeedback from "@/pages/convFeedback";
import endSurvey from "@/pages/endSurvey";
import atcapacity from "@/pages/atcapacity";
import welcome from "@/pages/welcome";
import mixerProgress from "@/pages/mixerProgress";
import autojoinRoom from "@/pages/autojoinRoom";
import agenda from "@/pages/agenda";
import message from "@/pages/message";
import matches from "./Matches";
import thanks from '@/pages/thanks.vue';
import thanksMeeting from '@/pages/thanksMeeting.vue';
import stage from "@/pages/stage";
import deviceSupport from '@/components/deviceSupport.vue';

export default {
  name: "app",
  components: {
    deviceSupport,
    selectQuestions: () => import("@/pages/selectQuestions"),
    ring: () => import("@/pages/ring"),
    profile,
    tou,
    convFeedback,
    endSurvey,
    atcapacity,
    welcome,
    message,
    mixerProgress,
    autojoinRoom,
    agenda,
    matches,
    room,
    thanks,
    thanksMeeting,
    stage,
    recordQuestion: () => import("@/pages/recordQuestion"),
    manageQuestions: () => import("@/pages/manageQuestions"),
  },
  props: {
    propUid: {
      type: String,
      required: false
    },
    debugMode: {
      type: Boolean,
      required: false
    },
    manager: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errorMsg: null,
      event: null,

      // User
      user: null,
      eventUser: null,
      eventUserBehavior: null,
      eventUsers: null,
      
      statePage: null,
      statePageParams: null,

      // Page
      pageClasses: {
        "stage": "w-100",
      },

      // Live Room
      roomId: "",

      // Test Automation
      debugAutoAccept: false,
      debugMuted: false,
      pageText: "",
    };
  },
  computed: {
    page() {
      return this.statePage || this.eventUser?.page;
    },
    pageParams() {
      if (this.statePage) return this.statePageParams || {};
      return this.eventUser?.pageParams || {};
    },
    isModerator() {
      return (
        this.$route.path.startsWith("/moderator") ||
        (this.user && this.user.moderator)
      );
    },
    eventUsersIds() {
      return this.eventUsers?.map(e => e.id) || null;
    }
  },
  watch: {
    debugMode: {
      immediate: true,
      handler() {
        log.log(`changing debugMode ${this.debugMode}`);
        this.debugAutoAccept = this.debugMode;
        this.debugMuted = this.debugMode;
      }
    },
  },
  async mounted() {
    initServerTime();
    if (this.isModerator) this.name = "Moderator";
    // Load user id
    var uid = null;
    if (this.$route.query.ignoreuid)
      log.log("Ignore localStorage userid");
    else if (this.propUid)
      uid = this.propUid;
    else if (this.$route.query.uid)
      uid = this.$route.query.uid;
    else {
      let suid = localStorage.getItem("userid");
      if (suid) 
        uid = suid;
      else if (this.$app.redirectToSignupFromHomepage)
        this.$router.push("/guest/signup");
    }
    if (uid)
      await this.bindUser(uid);
    this.$parent.hideNav = !this.$parent.user;
    this.$parent.hideTitle = !this.$parent.user;
    // autojoin event
    if (this.$route.params.eventId)
      this.bindEvent(this.$route.params.eventId, true);
    else if (this.user) {
      // join room
      if (this.$route.query.room)
        this.joinRoom(this.$route.query.room);
      else
        this.errorMsg = `No ${this.$app.eventName.toLowerCase()} selected, please join from the link provided.`;
    }
  },
  methods: {
    signToU(sign) {
      db.collection("LiveUsers")
        .doc(this.user.id)
        .update({ waiverSigned: sign });
    },
    async bindUser(uid) {
      let that = this;
      return new Promise(resolve => {
        that.$bind("user", db.collection("LiveUsers").doc(uid)).then(user => {
          if (!user) {
            log.error(`User ${uid} not found`);
            return;
          }
          document.title =
            (that.isModerator ? "Mod." : "Guest") + " - " + user.name;
          log.log("found user", user);
          resolve(user);
        });
      });
    },
    async bindEvent(eventId, autojoin) {
      let event = await this.$bind("event", db.collection("LiveEvents").doc(eventId));
      log.log("loaded Event: ", event);
      if (event) {
        this.$app.eventName = eventTypeNames[event.type] || this.$app.eventName;
        if (event.callOptions?.eventUsers)
          this.$bind("eventUsers", db.collection(`LiveEvents/${event.id}/users`));
      }
      if (event && autojoin) {
        
        this.bindEventUser(this.user.id, autojoin);
      } else
        this.errorMsg = `Could not load event ${eventId}`;
    },
    async bindEventUser(uid) {
      if (!this.event) {
        log.error("No event selected");
        return;
      }
      let col = db.collection(`LiveEvents/${this.event.id}/users`);
      let eventUser = await this.$bind("eventUser", col.doc(uid));
      registerUserToEvent(this.user, this.event.id);
      log.log("loaded eventUser:", eventUser);
      this.eventUserBehavior = getEventUserBehavior(this.user, this.event, this.eventUser, this);
      if (this.eventUserBehavior) {
        this.$watch("event", this.eventUserBehavior.onEventUpdate.bind(this.eventUserBehavior));
        this.$watch("eventUser", this.eventUserBehavior.onEventUserUpdate.bind(this.eventUserBehavior));
        await this.eventUserBehavior.init();
      }
    },
    async unbindEvent() {
      this.$unbind("event");
      this.$unbind("eventUser");
    },
    setPage() {
      log.log("setPage", this.pageText);
      this.eventUserBehavior.setPage(this.pageText);
    },
    // Live Room
    async joinRoom(roomId) {
      log.log("joining room", roomId);
      this.roomId = roomId;
    },
    async roomJoined() {
      chatSendStatus(this.user.id, "Joined room.", true);
    },
    async roomLeft(params) {
      log.log("roomLeft", params);
      chatSendStatus(this.user.id, "Left room.", true);
      if (this.eventUserBehavior) {
        await this.eventUserBehavior.on("roomLeft", params);
      }
      this.roomId = null;
    },
  }
};
</script>

<style>
/* News */
[name="adapterjs-alert"] {
  visibility: hidden;
}
</style>