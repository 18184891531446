<template>
<div>
  <video playsinline autoplay muted loop width="50%" v-if="event.welcomeVideo">
    <source :src="event.welcomeVideo.source" type="video/mp4">
  </video>
  <div class="text mt-3" v-for="(m, index) in event.welcomeMessages" :key="index">
    {{ inject(m, $app) }}
  </div>
  <progress/>
  <event-user-list
    v-if="event.workflowOptions.welcomeShowsUsers"
    class="mt-3"
    :eventUser="eventUser"
    :eventUsers="eventUsers"/>
</div>
</template>

<script>
import { inject } from "@/services/utils";
import eventUserList from '../components/eventUserList.vue';
import { db } from "@/services/db";

export default {
  components: { eventUserList },
  props: {
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      eventUsers: null,
    };
  },
  mounted() {
    this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
  },
  methods: {
    inject,
  }
}
</script>

<style scoped>

.text {
  white-space: pre-line;
}

</style>