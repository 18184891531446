<template>
<div>
  <div v-if="params.autoJoin">
    <preview-gum class="shadow-lg p-2 mb-2 bg-white rounded"/>
    <button class="btn btn-primary mt-2" @click="joinRoom(true)">I'm ready!</button>
  </div>
  <div v-else-if="eventUser.role == 'host'">
    You're the Host.<br/>
    <br/>
    Please share this link with your guests:<br/>
    <shareable :content="`${$app.appURL}/guest/join/${event.id}`"/>
    <br/>
    When ready to join click here.<br/>
    <button class="btn btn-primary mt-5" @click="joinRoom(true)">Join Room</button>
  </div>
  <div v-else>
    <div v-if="eventUser.queuePosition == event.queueIndex">
      You're next in line.<br/>
      <button class="btn btn-primary" @click="joinRoom">Join Room</button>
    </div>
    <div v-else-if="eventUser.queuePosition > event.queueIndex">
      You're number {{ eventUser.queuePosition - event.queueIndex + 1 }} in line.
    </div>
    <div v-else>
      You've completed your calls.<br/>
      We hope you had a good conversation.<br/>
      <br/>
      Thank you!
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
import shareable from '@/components/shareable';
import previewGum from "@/components/previewGum";
let log = getLog("autojoinRoom");

export default {
  components: { 
    shareable,
    previewGum,
  },
  props: {
    event: Object,
    eventUser: Object,
    params: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
    log.log("mounted", this.params);
  },
  methods: {
    joinRoom(host) {
      log.log("joinRoom", host, this.params);
      this.$emit("join-room", this.params.roomId);
    }
  }
}
</script>

<style>

</style>