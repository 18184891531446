<template>
<div>
  <!-- Message -->
  <h2 class="mb-3 mt-md-5">{{ $app.eventName }} in progress</h2>
  <div class="mb-3" v-if="params.message">
    {{ params.message }}
  </div>
  <!-- Preview for video -->
  <div class="mb-2" v-if="event.callOptions && event.callOptions.video">
    <div v-if="!showPreviewGum">
      <button class="btn btn-primary" @click="showPreviewGum = !showPreviewGum">Show Preview</button>
    </div>
    <div v-if="showPreviewGum" class="shadow-lg p-2 mb-2 bg-white rounded">
      <div class="mb-2">Please check your video setup.</div>
      <preview-gum/>
      <button class="btn btn-primary mt-2" @click="showPreviewGum = !showPreviewGum">My setup is working!</button>
    </div>
  </div>
  <!-- Progress -->
  <div class="mb-3">
    <div class="form-inline sub-mt-2 sub-mr-2" v-for="(c, index) in conversations" :key="index">
      <debug-obj v-if="$debug.isOn" label="conv" :objData="c"/>
      <div>{{ getOtherName(c) }}</div>
      <div>{{ getLike(c) }}</div>
      <button  v-if="c.status == 'inprogress' && c.roomId" 
        class="btn btn-primary"
        @click="$emit('join-room', c.roomId)">Join</button>
    </div>
  </div>
  <div class="mt-3 mt-md-5" v-if="autoJoin">
    Joining in a few seconds...
  </div>
  <div class="mt-3 mt-md-5" v-if="!eventUser.maxConversations || eventUser.conversationCount < eventUser.maxConversations">
    <div v-if="eventUser.conversationCount < 1">
      Please wait for your first call...
    </div>
    <div v-else>
      Please wait for your next call...
    </div>
  </div>
  <div v-else-if="event.workflowOptions.showMatches">
    This was your last call for today, get ready to see your matches...
  </div>
  <div v-else>
    This was your last call for today, we hope you had a good time.
  </div>
  <debug-obj v-if="$debug.isOn" label="likes" :objData="likes"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("mixer-progress");
import { db } from '@/services/db';
import { whereDocIdStartWith } from "@/services/dbutils";
import { bindCombineArrays, bindMap } from "@/services/utils";
import previewGum from '../components/previewGum.vue';

export default {
  components: { previewGum },
  props: {
    event: Object,
    eventUser: Object,
    params: Object,
  },
  data() {
    return {
      showPreviewGum: false,
      autoJoin: false,

      conversations: [],
      convA: [],
      convB: [],

      likes: {},
      likes_asArray: [],
    }
  },
  watch: {
    conversations() {
      let last = this.conversations.at(-1);
      log.log("conversations updated, last=", last);
      if (last.status == 'inprogress') {
        this.autoJoin = true;
        setTimeout(() => {
          this.$emit('join-room', last.roomId);
        }, 5000);
      }
    },
  },
  mounted() {
    log.log("mounted", this.params);
    this.$bind("convA", db.collection(`LiveEvents/${this.event.id}/conversations`).where("u1.id", "==", this.eventUser.id));
    this.$bind("convB", db.collection(`LiveEvents/${this.event.id}/conversations`).where("u2.id", "==", this.eventUser.id));
    bindCombineArrays(this, "conversations", ["convA", "convB"], (arr) => {
      arr.sort((a, b) => a.start.seconds - b.start.seconds);
      return arr;
    });
    bindMap(this, "likes", whereDocIdStartWith(db.collection(`LiveEvents/${this.event.id}/matches`), this.eventUser.id));
    this.showPreviewGum = this.eventUser.conversationCount < 1;
  },
  methods: {
    getOther(c) {
      if (c.u1.id == this.eventUser.id)
        return c.u2;
      if (c.u2.id == this.eventUser.id)
        return c.u1;
    },
    getOtherName(c) {
      return this.getOther(c).name;
    },
    getLike(c) {
      let r = this.likes[this.eventUser.id + '_' + this.getOther(c).id] || {};
      return r.pick ? "👍" : r.discard ? "👎" : "";
    }
  }
}
</script>

<style scoped>

.text {
  white-space: pre-line;
}

</style>