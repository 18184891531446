<template>
<div class="inner-container"
  :class="classes" 
  v-if="!u.invisible">
  <div class="video-overlay tl">
    <font-awesome-icon class="ml-1 mr-1" icon="volume-mute" :style="{ color: 'white' }" size="lg" v-if="config.volumeMuted"/>
    <font-awesome-icon icon="microphone-slash" :style="{ color: 'white' }" size="lg" v-if="u.muted"/>
    <font-awesome-icon class="ml-1" icon="microphone" :style="{ color: 'white' }" size="lg" v-else/>
    {{ u.name ? u.name : "Waiting..." }}
  </div>
  <div class="video-overlay bl" v-if="u.note && config.showNote">
    {{ u.note }}
  </div>
  <div class="video-overlay br cursor-pointer" v-if="u.stream && (config.canExpand || config.canReset || config.canClose || config.canVignette)">
    <font-awesome-icon class="m-1" icon="expand" :style="{ color: 'white' }" size="lg" v-if="config.canExpand" @click="$emit('expand')"/>
    <font-awesome-icon class="m-1" icon="compress" :style="{ color: 'white' }" size="lg" v-if="config.canReset" @click="$emit('reset')"/>
    <font-awesome-icon class="m-1" icon="window-close" :style="{ color: 'white' }" size="lg" v-if="config.canClose" @click="$emit('close')"/>
    <font-awesome-icon class="m-1" icon="window-restore" :style="{ color: 'white' }" size="lg" v-if="config.canVignette" @click="$emit('vignette')"/>
  </div>
  <video :class="{ 'mirror' : u.local || u.mirror, 'square': config.displaySquare}" :srcObject.prop="u.stream" autoplay playsinline :muted="u.local || config.volumeMuted" />
  <div class="image-overlay image-container" v-if="u.image">
    <img :src="u.image" class="image-container"/>
  </div>
</div>  
</template>

<script>
export default {
  props: {
    u: null,
    classes: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

@import "./video.scss";

</style>
