<template>
<div>
  <div class="form-inline sub-mr-2">
    <b-form-input class="col" readonly :value="content" v-if="!config.hideContent"/>
    <button class="btn btn-primary" @click="share(content, shareMessage, shareTitle)" v-if="canShare">
      <font-awesome-icon icon="share" :style="{ color: 'white' }" size="sm"/>
    </button>
    <button class="btn btn-primary" @click="copy(content)" v-else>
      <font-awesome-icon icon="copy" :style="{ color: 'white' }" size="sm"/>
    </button>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("shareable");

export default {
  props: {
    content: String,
    shareMessage: String,
    shareTitle: String,
    config: {
      type: Object,
      default: () => { return {}; }
    }
  },
  computed: {
    canShare() {
      return this.shareMessage && navigator.share;
    },
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text);
    },
    share(url, text, title) {
      try {
        navigator.share({
          title: title || this.$app.eventName,
          text,
          url,
        })
      }
      catch (e) {
        log.log(e);
      }
    },
  }
}
</script>

<style>

</style>