import { render, staticRenderFns } from "./videoLayout.vue?vue&type=template&id=3cfda926&scoped=true&"
import script from "./videoLayout.vue?vue&type=script&lang=js&"
export * from "./videoLayout.vue?vue&type=script&lang=js&"
import style0 from "./videoLayout.vue?vue&type=style&index=0&id=3cfda926&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cfda926",
  null
  
)

export default component.exports